import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';
import { Link } from 'react-router-dom';

import Icon from '@/components/graphics/Icon';

const defaultPropValues = {
  onClick: () => {},
  buttonProps: {},
  disabled: false,
  className: '',
  buttonType: 'button',
  newPage: false,
};

/**
 * A flexible component for creating interactive elements
 * with common properties. CommonLink will automatically display
 * a <a> element, Link component, or <button> element, depending
 * on the passed props. This component is intended to be used
 * internally by other interactive components and not directly.
 */
const CommonLink = memo(
  forwardRef((providedProps, ref) => {
    const props = { ...defaultPropValues, ...providedProps };

    const renderContents = () => (
      <>
        {props.iconBefore ? <Icon type={props.iconBefore} /> : null}
        {!!props.children && <span>{props.children}</span>}
        {props.iconAfter ? <Icon type={props.iconAfter} /> : null}
      </>
    );

    const sharedProps = {
      className: props.className,
      disabled: props.disabled,
      onClick: props.onClick,
      ref,
    };

    let newPageProps = {};
    if (props.newPage) {
      newPageProps.target = '_blank';
      newPageProps.rel = 'noopener noreferrer';
    }

    // <Link>
    if (props.to) {
      return (
        <Link {...newPageProps} to={props.to} {...sharedProps}>
          {renderContents()}
        </Link>
      );
    }

    // <a>
    if (props.href) {
      return (
        <a
          href={props.href}
          target={props.target ? props.target : null}
          rel={props.rel ? props.rel : null}
          {...newPageProps}
          {...sharedProps}
        >
          {renderContents()}
        </a>
      );
    }

    // Fallback <button>
    return (
      <button {...sharedProps} {...props.buttonProps} type={props.buttonType}>
        {renderContents()}
      </button>
    );
  }),
);

const commonLinkProps = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  newPage: PropTypes.bool,
  // Forward refs to the element
  /* TODO it seems like "react/forbid-foreign-prop-types" is not fully disabling
   * which is totally odd so for now we're force disabling
   *  Important links here:
   *  - Rule that don't fully disable:
   *  https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/forbid-foreign-prop-types.md
   *  - Issue that brought us to the lib: https://github.com/ReactTraining/react-router/issues/394
   */
  // eslint-disable-next-line
  to: PropTypes.oneOfType([Link.propTypes?.to, PropTypes.string]),
  rel: PropTypes.string,
  target: PropTypes.string,
};

CommonLink.propTypes = {
  ...Link.propTypes,
  ...commonLinkProps,
  buttonProps: PropTypes.object,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
};

export { CommonLink, commonLinkProps };

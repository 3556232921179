import PropTypes from 'prop-types';
import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { allBreakpoints } from '@/lib/breakpoints';

/**
 * Automatically displays `children` based on the viewport
 * size.
 *
 * `min` is the minimum viewport size where the content will display
 * `max` is the maximum viewport size where the content will display
 */
const Mq = ({ children, min, max, ...otherProps }) => {
  const matches = useMediaQuery({
    ...(min && { minWidth: allBreakpoints()[min] }),
    ...(max && { maxWidth: allBreakpoints()[max] - 1 }),
    ...otherProps,
  });

  if (typeof children === 'function') {
    return <>{children(matches)}</>;
  }

  if (!matches) {
    return null;
  }

  return <>{children}</>;
};

Mq.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
};

export default memo(Mq);
